import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import FlowGrid from '../../components/FlowGrid';
import CaptionedImage from '../../components/CaptionedImage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`A problem that many people run into after writing enough SwiftUI is how to make it so that two views inside an `}<inlineCode parentName="p">{`HStack`}</inlineCode>{` have equal width, such that if the `}<inlineCode parentName="p">{`HStack`}</inlineCode>{` grows both inner views expand proportionally.`}</p>
    <p>{`There are several articles online talking about how to do this with `}<inlineCode parentName="p">{`GeometryReader`}</inlineCode>{` or preferences, but for the common case of just having columns equally distributed, it's actually easier to just use frames. If you set the views' frames to both have a min width of 0 and max width of infinity, SwiftUI's layout system distributes them equally:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`struct`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ContentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` body: some View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`HStack`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`alignment`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`top`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`spacing`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Rectangle`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`fill`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(Color.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`blue`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`frame`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`minWidth`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`maxWidth`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`infinity`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Rectangle`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`fill`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(Color.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`red`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`frame`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`minWidth`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`maxWidth`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`infinity`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`padding`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`().`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`background`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(Color.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`white`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`This produces the result we want regardless of screen size:`}</p>
    <FlowGrid columns={2} mdxType="FlowGrid">
    <CaptionedImage max={300} filename="swiftui-columns-small.png" alt="Red and blue squares filling space equally on a small screen" caption="" mdxType="CaptionedImage" />
    <CaptionedImage max={500} filename="swiftui-columns-large.png" alt="Red and blue squares filling space equally on a large screen" caption="" mdxType="CaptionedImage" />
    </FlowGrid>
    <p>{`Hopefully this helps you simplify your SwiftUI code for when you only need to account for the common case of two columns 👍`}</p>
    <blockquote>
      <p parentName="blockquote">{`Sample code for this project is available at `}<a parentName="p" {...{
          "href": "https://github.com/noahsark769/NGSwiftUIEqualWidthColumnsExample"
        }}>{`NGSwiftUIEqualWidthColumnExample`}</a>{`.`}</p>
    </blockquote>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      